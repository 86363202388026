import React, { memo, useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Loadable from 'react-loadable';
import Layout from '../components/shared/layout';
import SEO from '../components/SEO/SEO';
// import RecentPosts from '../components/Content/RecentPosts';
import Steps from '../components/Content/Home/Steps';
// import Games from '../components/Content/Home/Games';
import { breakpoints } from '../utils/style';
import HomePageContext from '../components/shared/Context/HomePageContext';
// import PlayForFree from '../components/Content/Home/PlayForFree';
// import CommentsStaticLoadmore from '../components/Content/Withdraw/CommentsStaticLoadmore';
// import WhySbobet from '../components/shared/WhySbobet';
// import SBOSBA from '../components/shared/SBOSBA';
import GetWindowWidth from '../components/shared/GetWindowWidth';
import CookieDisableComponent from '../components/shared/CookieDisableError';

const GamesComponent = Loadable({
  loader: () => import('../components/Content/Home/Games'),
  loading: () => <div />,
});
const SBOSBAComponent = Loadable({
  loader: () => import('../components/shared/SBOSBA'),
  loading: () => <div />,
});
const RecentPostsComponent = Loadable({
  loader: () => import('../components/Content/RecentPosts'),
  loading: () => <div />,
});
const PlayForFreeComponent = Loadable({
  loader: () => import('../components/Content/Home/PlayForFree'),
  loading: () => <div />,
});
const WhySbobetComponent = Loadable({
  loader: () => import('../components/shared/WhySbobet'),
  loading: () => <div />,
});
const CommentsStaticLoadmoreComponent = Loadable({
  loader: () => import('../components/Content/Withdraw/CommentsStaticLoadmore'),
  loading: () => <div />,
});

const Wrapper = styled('div')`
  margin-bottom: 40px;
  .gatsby-image-wrapper {
    border-radius: 4px;
  }
  @media (max-width: ${breakpoints.xs}) {
    margin-bottom: 20px;
  }
`;

// REFACTOR
// Spread, maybe something more
const HomePage = ({ data }) => {
  const windowWidth = GetWindowWidth();
  const isDesktop = windowWidth > 480;
  const homePageNode = data.allWordpressPage.edges[0].node;
  // const featuredMedia = homePageNode.featured_media;
  const metaTitle =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_title
      ? homePageNode.yoast_meta.yoast_wpseo_title
      : homePageNode.title + process.env.SITE_NAME_SHORT;
  const metaDesc =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_metadesc
      ? homePageNode.yoast_meta.yoast_wpseo_metadesc
      : '';
  const steps = homePageNode.acf;
  const showPlayForFree = true;
  const playForFreeTitle = homePageNode.acf.play_for_free_title;
  const playForFreeIframe = homePageNode?.acf?.play_for_free_video_file;
  const playForFreeVideoCover = homePageNode.acf.play_for_free_video_cover;
  const gatsbyFreeSpinGames = homePageNode.acf.gatsby_freespin_games;
  const [localError, setLocalError] = useState(false);

  useEffect(() => {
    try {
      if (window.localStorage) {
        setLocalError(false);
      }
    } catch (error) {
      setLocalError(true);
    }
  });

  if (!localError) {
    return (
      <HomePageContext.Provider key="home-context-provider">
        <Layout>
          <SEO title={metaTitle} description={metaDesc} showSchema />
          <div id="home-content-all">
            <Wrapper>
              <Link to="/world-cup-2022/">
                {isDesktop ? (
                  <Img
                    fluid={data.placeholder_desktop.childImageSharp.fluid}
                    alt="Free Bonus 8% Max 8000"
                    fadeIn={false}
                    loading="eager"
                  />
                ) : (
                  <Img
                    fluid={data.placeholder_mobile.childImageSharp.fluid}
                    alt="Free Bonus 8% Max 8000"
                    fadeIn={false}
                    loading="eager"
                  />
                )}
              </Link>
            </Wrapper>
            <Steps step1={steps} step2={steps} step3={steps} />
            {showPlayForFree && (
              <PlayForFreeComponent
                play_for_free_title={playForFreeTitle}
                play_for_free_video_cover={playForFreeVideoCover}
                play_for_free_iframe={playForFreeIframe?.source_url}
                gatsby_freespin_games={gatsbyFreeSpinGames}
              />
            )}
            <GamesComponent />
            <SBOSBAComponent />
            <RecentPostsComponent />
            <WhySbobetComponent />
            <CommentsStaticLoadmoreComponent />
          </div>
        </Layout>
      </HomePageContext.Provider>
    );
  }

  return (<CookieDisableComponent />);

  // return (
  //   <HomePageContext.Provider key="home-context-provider">
  //     <Layout>
  //       <SEO title={metaTitle} description={metaDesc} showSchema />
  //       <div id="home-content-all">
  //         <Wrapper>
  //           <Link to="/topup-bonus/">
  //             {isDesktop ? (
  //               <Img
  //                 fluid={data.placeholder_desktop.childImageSharp.fluid}
  //                 alt="Free Bonus 8% Max 8000"
  //                 fadeIn={false}
  //                 loading="eager"
  //               />
  //             ) : (
  //               <Img
  //                 fluid={data.placeholder_mobile.childImageSharp.fluid}
  //                 alt="Free Bonus 8% Max 8000"
  //                 fadeIn={false}
  //                 loading="eager"
  //               />
  //             )}
  //           </Link>
  //         </Wrapper>
  //         <Steps step1={steps} step2={steps} step3={steps} />
  //         {showPlayForFree && (
  //           <PlayForFreeComponent
  //             play_for_free_title={playForFreeTitle}
  //             play_for_free_video_cover={playForFreeVideoCover}
  //             play_for_free_iframe={playForFreeIframe}
  //             gatsby_freespin_games={gatsbyFreeSpinGames}
  //           />
  //         )}
  //         <GamesComponent />
  //         <SBOSBAComponent />
  //         <RecentPostsComponent />
  //         <WhySbobetComponent />
  //         <CommentsStaticLoadmoreComponent />
  //       </div>
  //     </Layout>
  //   </HomePageContext.Provider>
  // );
};

export default memo(HomePage);

export const pageQuery = graphql`
  query {
    allWordpressPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          title
          content
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          acf {
            step_1_url
            step_2_url
            step_3_url
            step_1_title
            step_2_title
            step_3_title
            top_slider {
              alt_text
              title
              path
              acf {
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            top_mobile_slider {
              alt_text
              title
              path
              acf {
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            payment_systems {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            play_for_free_title
            play_for_free_video_file {
              source_url
            }
            play_for_free_video_cover {
              alt_text
              title
              path
              localFile {
                childImageSharp {
                  fluid(maxWidth: 390) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            gatsby_freespin_games {
              alt_text
              title
              path
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 254) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
        }
      }
    }
    placeholder_desktop: file(relativePath: { eq: "worldcup/worldcup-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    placeholder_mobile: file(relativePath: { eq: "worldcup/worldcup-banner-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 576) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
