/* eslint-disable max-len */
import React from 'react';

const StepsArrow = () => (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="112" viewBox="0 0 200 112">
      <defs>
        <linearGradient id="prefix__a" x1="97.773%" x2="61.487%" y1="59.43%" y2="34.659%">
          <stop offset="0%" stopColor="#14AEFF" />
          <stop offset="100%" stopColor="#2DA5FE" stopOpacity="0" />
        </linearGradient>
      </defs>
      <path fill="url(#prefix__a)" fillRule="evenodd" d="M343 0l40.284 51.044c2.293 2.906 2.293 7.006 0 9.912L343 112H185V0h158z" opacity=".2" transform="translate(-185)" />
    </svg>

  </>
);

export default StepsArrow;
